// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brandy-js": () => import("./../../src/pages/brandy.js" /* webpackChunkName: "component---src-pages-brandy-js" */),
  "component---src-pages-events-js": () => import("./../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-get-involved-js": () => import("./../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-gin-js": () => import("./../../src/pages/gin.js" /* webpackChunkName: "component---src-pages-gin-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nonconformists-js": () => import("./../../src/pages/nonconformists.js" /* webpackChunkName: "component---src-pages-nonconformists-js" */),
  "component---src-pages-rum-js": () => import("./../../src/pages/rum.js" /* webpackChunkName: "component---src-pages-rum-js" */),
  "component---src-pages-shop-js": () => import("./../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-spirit-collections-js": () => import("./../../src/pages/spirit-collections.js" /* webpackChunkName: "component---src-pages-spirit-collections-js" */),
  "component---src-pages-team-js": () => import("./../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tequila-harvest-production-js": () => import("./../../src/pages/tequila-harvest-production.js" /* webpackChunkName: "component---src-pages-tequila-harvest-production-js" */),
  "component---src-pages-tequila-js": () => import("./../../src/pages/tequila.js" /* webpackChunkName: "component---src-pages-tequila-js" */),
  "component---src-pages-vodka-js": () => import("./../../src/pages/vodka.js" /* webpackChunkName: "component---src-pages-vodka-js" */),
  "component---src-pages-vr-experience-js": () => import("./../../src/pages/vr-experience.js" /* webpackChunkName: "component---src-pages-vr-experience-js" */),
  "component---src-pages-whiskey-js": () => import("./../../src/pages/whiskey.js" /* webpackChunkName: "component---src-pages-whiskey-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-standardpage-js": () => import("./../../src/templates/standardpage.js" /* webpackChunkName: "component---src-templates-standardpage-js" */)
}

